:root {
    --app-height: 100%;
 }
 
 body {
     color: #fff;
     background: #1b1c21;
 }
 
 a, a:hover, .m-link, button {
     text-decoration: none;
     -moz-transition: all 0.2s ease-out;
     -o-transition: all 0.2s ease-out;
     -webkit-transition: all 0.2s ease-out;
     transition: all 0.2s ease-out;
 }
 
 button {
     border: none;
     cursor: pointer;
 }
 
 input {
     outline: none;
 }
 
 input::-webkit-input-placeholder { color: #717276; }
 input::-moz-placeholder          { color: #717276; }
 input:-moz-placeholder           { color: #717276; }
 input:-ms-input-placeholder      { color: #717276; }
 
 #wrapper {
 
     display: flex;
     height: 100vh;
 
     #sidebar {
         width: 20%;
         padding: 25px 15px;
         background: #0f0f12;
 
         ul {
             padding: 0;
             margin: 0;
             list-style: none;
 
             li {
 
                 margin-bottom: 10px;
 
                 &.m-active {
 
                     button {
                         cursor: default;
                         background: #2d2f37;
 
                         span {
                             background-color: #5c4ffe;
                         }
 
                     }
 
                 }
 
                 &:not(.m-active) {
 
                     button:hover {
                         opacity: 0.8;
                     }
 
                 }
 
                 button {
                     display: flex;
                     align-items: center;
                     width: 100%;
                     color: #fff;
                     padding: 12px 15px;
                     text-decoration: none;
                     border-radius: 10px;
                     background-color: transparent;
 
                     span {
                         content: "";
                         display: block;
                         width: 30px;
                         height: 30px;
                         margin-right: 15px;
                         border-radius: 6px;
                         background: #24262d no-repeat center center;
                         background-size: 16px;
                     }
 
                     small {
                         font-size: 16px;
                     }
 
                 }
 
             }
 
         }
 
     }
 
     #main {
         width: 80%;
         padding-bottom: 25px;
     }
 
     #header {
         display: flex;
         padding: 25px;
 
         .header__search {
             flex: auto;
             padding-right: 25px;
             position: relative;
 
             &::before {
                 content: "";
                 display: block;
                 width: 24px;
                 height: 24px;
                 background: url(./img/search.svg) no-repeat center center;
                 background-size: contain;
                 position: absolute;
                 left: 20px;
                 top: 50%;
                 transform: translateY(-50%);
                 opacity: 0.5;
             }
 
             input {
                 width: 100%;
                 height: 54px;
                 color: #fff;
                 background: #0f0f12;
                 border: none;
                 border-radius: 10px;
                 padding: 0 25px 0 60px;
             }
 
         }
 
         .header__buttons {
             display: flex;
             gap: 25px;
         }
 
         .header__buttons > * {
             display: flex;
             height: 100%;
 
             small {
                 font-size: 16px;
             }
 
         }
 
         .header__buttons > *:last-child {
             margin-right: 0;
         }
 
     }
 
     #content {
         display: flex;
         height: calc(100% - 104px);
         padding-right: 25px;
         position: relative;
     }
 
     .b-notes {
         width: 30%;
         padding: 0 7px;
         position: relative;
         overflow: hidden;
 
         .notes__wrp {
             display: flex;
             flex-direction: column;
             flex-wrap: wrap;
             gap: 15px;
             padding: 0 18px;
         }
 
         .b-note:not(.m-loading) {
             -moz-transition: all 0.2s ease-out;
             -o-transition: all 0.2s ease-out;
             -webkit-transition: all 0.2s ease-out;
             transition: all 0.2s ease-out;
         }
 
         .b-note {
             color: #e8e8e8;
             padding: 15px 20px;
             background: #2d2f37;
             border-radius: 10px;
             position: relative;
 
             h4 {
                 font-size: 16px;
                 padding-right: 20px;
                 position: relative;
             }
 
             &.m-file h4:after {
                 content: "";
                 display: block;
                 width: 20px;
                 height: 20px;
                 border-radius: 6px;
                 background: url(./img/file.svg) no-repeat center center;
                 background-size: contain;
                 position: absolute;
                 right: 0;
                 top: 50%;
                 transform: translateY(-50%);
                 opacity: 0.8;
             }
 
             .note__bottom {
                 display: flex;
                 justify-content: space-around;
                 font-size: 14px;
                 color: #ffffff59;
 
                 p {
                     flex: auto;
                     margin: 0;
                 }
 
                 span {
                     position: absolute;
                     top: 0;
                     right: 0;
                     background: #25262d;
                     padding: 1px 10px;
                     font-size: 11px;
                     line-height: 21px;
                     border-radius: 0 10px 0 10px;
                     opacity: 0.5;
                 }
 
                 span {
                     position: absolute;
                     top: 0;
                     right: 0;
                     background: #25262d;
                     padding: 1px 10px;
                     font-size: 11px;
                     line-height: 21px;
                     border-radius: 0 10px 0 10px;
                     opacity: 0.5;
                 }
 
             }
 
             &:hover {
                 cursor: pointer;
                 opacity: 0.8;
             }
 
             &.m-nothing {
                 text-align: center;
                 cursor: default;
                 span {
                     color: "#fff";
                     opacity: 0.5;
                 }
 
                 &:hover {
                     opacity: 1;
                 }
             }
 
             &.m-active {
                 background: #5c4ffe;
             }
 
             &.m-active .note__bottom span {
                 background: #4131ff;
                 color: #fff;
                 opacity: 0.4;
             }
 
         }
 
         .b-note:last-child {
             margin-bottom: 0;
         }
 
     }
 
     .b-content {
         width: 70%;
 
         .content__title {
 
             position: relative;
             border-radius: 10px;
             overflow: hidden;
             isolation: isolate;
 
             .b-title_buttons {
                 display: flex;
                 position: absolute;
                 right: 0;
                 top: 50%;
                 transform: translateY(-50%);
 
                 button {
                     content: "";
                     display: block;
                     width: 30px;
                     height: 30px;
                     margin-right: 15px;
                     border-radius: 6px;
                     background: url(./img/important.svg) #198754 no-repeat center center;
                     background-size: 16px;
 
                     &:hover {
                         opacity: 0.8;
                     }
                 }
 
                 button.m-active {
                    background-image: url(./img/important_fill.svg);
                 }
 
                 button.m-remove {
                     background-image: url(./img/trash.svg);
                     background-color: rgb(255, 63, 63);
                 }
 
             }
 
         }
 
         .content__title input, .b-editor {
             background: #25262d;
             border-radius: 10px;
         }
 
         .content__title input {
             width: 100%;
             color: #fff;
             height: 54px;
             border: none;
             padding: 0 105px 0 25px;
         }
 
         .b-editor  {
             
             height: calc(100% - 74px);
             margin-top: 20px;
             // padding-bottom: 20px;
             padding-bottom: 0;
             position: relative;
             overflow: hidden;
             isolation: isolate;
 
             .editor {
                 height: calc(100% - 20px);
                 color: #fff;
                 
                 > * {
                     border: none;
                 }
 
                 .ql-toolbar {
                     border-bottom: 1px solid #1b1c21;
                     padding: 14px 16px;
                 }
 
                 .ql-container {
                     height: calc(100% - 43.37px);
                 }
 
             }
 
         }
 
         .content__buttons {
             display: none;
             margin-top: 20px;
         }
 
         button.m-link {
             width: 100%;
             height: 50px;
             align-items: center;
             justify-content: center;
         }
 
     }
 
 
 }
 
 .m-link {
     display: flex;
     align-items: center;
     justify-content: center;
     color: #fff;
     padding: 0 20px;
     background: #5c4ffe;
     border: none;
     border-radius: 10px;
 
     span {
 
         display: flex;
         align-items: center;
 
         &::before {
             content: "";
             display: block;
             width: 26px;
             height: 26px;
             background: url(./img/plus.svg) no-repeat center center;
             background-size: contain;
         }
 
     }
 
     &.m-save small {
         width: 45px;
         text-align: center;
     }
 
     &.m-save.m-saved {
         background-color: #198754;
     }
 
     &.m-save span::before {
         width: 20px;
         height: 20px;
         background-image: url(./img/save.svg);
         margin-right: 10px;
         -moz-transition: all 0.4s ease-out;
         -o-transition: all 0.4s ease-out;
         -webkit-transition: all 0.4s ease-out;
         transition: all 0.4s ease-out;
     }
 
     &.m-close {
        display: none !important;
        background-color: #ff403f;
     }
 
     &.m-close span::before {
        transform: rotate(45deg);
     }
 
 }
 
 .m-link:not(.m-close):hover {
     background: #4f45db;
 }
 
 .ql-snow .ql-picker {
     color: #fff;
 }
 
 .ql-snow .ql-fill, .ql-snow .ql-stroke.ql-fill {
     fill: #fff;
 }
 
 .ql-editor ol, .ql-editor ul {
     padding-left: 0;
 }
 
 .ql-picker-label {
     -moz-transition: all 0.2s ease-out;
     -o-transition: all 0.2s ease-out;
     -webkit-transition: all 0.2s ease-out;
     transition: all 0.2s ease-out;
 }
 
 .ql-snow .ql-stroke {
     stroke: #fff;
     -moz-transition: all 0.2s ease-out;
     -o-transition: all 0.2s ease-out;
     -webkit-transition: all 0.2s ease-out;
     transition: all 0.2s ease-out;
 }
 
 .ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow .ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow .ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
     color: #5c4ffe;
 }
 
 .ql-snow.ql-toolbar button:hover .ql-stroke, .ql-snow .ql-toolbar button:hover .ql-stroke, .ql-snow.ql-toolbar button:focus .ql-stroke, .ql-snow .ql-toolbar button:focus .ql-stroke, .ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow .ql-toolbar button.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow.ql-toolbar button:hover .ql-stroke-miter, .ql-snow .ql-toolbar button:hover .ql-stroke-miter, .ql-snow.ql-toolbar button:focus .ql-stroke-miter, .ql-snow .ql-toolbar button:focus .ql-stroke-miter, .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
     stroke: #5c4ffe;
 }
 
 .ql-editor {
     font-size: 16px;
     padding: 25px;
     padding-bottom: 0;
 }
 
 .ql-editor.ql-blank::before {
     font-size: 16px;
     font-style: normal;
     color: #717276;
     left: 25px;
     right: 25px;
 }
 
 .ql-snow a {
     color: #4f45db;
 }
 
 .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label, .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
     border-color: transparent;
 }
 
 .ql-snow .ql-picker.ql-expanded .ql-picker-options {
     top: calc(100% + 5px);
 }
 
 .ql-snow .ql-picker-options {
     background-color: #1b1c21;
     border-radius: 8px;
     padding: 0 10px 5px 10px;
 }
 
 .ql-snow .ql-picker-options .ql-picker-item:before {
     -moz-transition: all 0.2s ease-out;
     -o-transition: all 0.2s ease-out;
     -webkit-transition: all 0.2s ease-out;
     transition: all 0.2s ease-out;
 }
 
 .ql-snow .ql-picker-options .ql-picker-item {
     padding-bottom: 3px;
     padding-top: 3px;
 }
 
 .ql-snow .ql-picker-options .ql-picker-item:first-child {
     padding-bottom: 0;
 }
 
 .ql-snow .ql-tooltip {
     background-color: #1b1c21;
     border: none;
     box-shadow: 0px 0px 5px #1b1c21;
     color: #fff;
     padding: 12px 15px;
     border-radius: 8px;
 }
 
 .ql-snow .ql-tooltip input[type=text] {
     border: none;
     height: 30px;
     padding: 0 12px;
     width: 170px;
     background-color: #25262d;
     border-radius: 8px;
     color: #fff;
 }
 
 .ql-snow .ql-tooltip a {
     -moz-transition: all 0.2s ease-out;
     -o-transition: all 0.2s ease-out;
     -webkit-transition: all 0.2s ease-out;
     transition: all 0.2s ease-out;
 }
 
 .ql-snow .ql-tooltip a:hover {
     opacity: 0.8;
 }
 
 .auth {
     display: flex;
     align-items: center;
     justify-content: center;
     height: 100%;
     background: #1b1c21;
     position: fixed;
     width: 100%;
 
     .b-login {
         background: #1b1c21;
         padding: 25px;
         border-radius: 10px;
         width: 350px;
         margin-top: -80px;
 
         img {
             width: 80px;
             margin: 0 auto 50px;
             display: table;
             height: 80px;
         }
 
         input {
             width: 100%;
             color: #fff;
             height: 54px;
             border: 1px solid #25262d;
             padding: 0 25px;
             background: #25262d;
             border-radius: 10px;
             text-align: center;
             letter-spacing: 5px;
             font-weight: 500;
         }
 
         input.m-error {
             border-color: #ff403f;
         }
 
         button {
             width: 100%;
             height: 54px;
             margin-top: 20px;
         }
 
     }
 
 }
 
 // RESPONSIVE
 
 @media (max-width: 1399.98px) {
 
     #wrapper {
 
         .b-content {
             width: 63%;
         }
     
         .b-notes {
             width: 37%;
         }
 
     }
 
 }
 
 @media (max-width: 1199.98px) {
 
     #wrapper {
 
         #sidebar {
 
             width: auto;
 
             ul li button {
 
                 span {
                     margin-right: 0;
                 }
 
                 small {
                     display: none;
                 }
 
             } 
 
         }
 
         #main {
             width: auto;
             flex: auto;
         }
         
     }
 
 }
 
 @media (max-width: 991.98px) {

    body {
        overflow: hidden;
    }
 
     #wrapper {
         flex-direction: column;
         align-items: flex-start;
 
        #header .header__buttons > .m-save {
             display: none;
        }

        #header .header__buttons > .m-link.m-close {
            display: flex !important;
        }

        #header .header__buttons > .m-link.m-hidden {
            display: none !important;
        }
 
         #main {
             width: 100%;
             height: calc(100vh - 84px);
             overflow: hidden;
         }
 
         #sidebar {
 
             width: 100%;
             padding: 0 15px;
             height: 84px;
             align-items: center;
             display: flex;
         
             ul {
 
                 display: flex;
                 justify-content: space-around;
                 width: 100%;
 
                 li {
                     margin-bottom: 0;
                     flex: auto;
 
                     button {
                         padding: 0 10px;
                         justify-content: center;
                     }
 
                 }
 
                 li.m-active button {
                     background: transparent;
                 }
                 
                 li button span {
                     width: 100%;
                     height: 54px;
                     margin-right: 0;
                     border-radius: 10px;
                 }
 
             }
 
         }
 
         #content {
 
             padding-right: 0;
             align-items: flex-start;
 
             .b-content {
                 display: none;
                 position: absolute;
                 top: 0;
                 left: 0;
                 background: #1b1c21;
                 width: 100%;
                 height: 100%;
                 padding: 0 25px;
 
                 .b-editor {
                     height: calc(100% - 139px);
                 }
 
                 .content__buttons {
                     display: flex;
                 }
 
             }
 
             .b-content.m-active {
                 display: block;
             }
 
             .b-notes {
                 width: 100%;
                 display: flex;
                 flex-wrap: wrap;
                 padding: 0 2px;
                 height: 100%;
                 align-items: flex-start;
 
                 .notes__wrp {
                    padding: 0 23px;
                    flex-direction: row;
                 }
 
                 .b-note {
                    width: calc(50% - 7.5px)
                 }
 
                 .b-note:nth-of-type(-n+2) {
                     margin-top: 0;
                 }
 
             }
 
             button.m-link {
                 display: flex;
             }
 
 
         }
 
     }
 
     #wrapper .b-notes .b-note.m-active {
         background: #2d2f37;
     }
 
 }
 
 @media (max-width: 767.98px) {
 
    #wrapper {

        #main {

            #content {
    
                .b-notes {

                    .notes__wrp {
                        flex-direction: column;
                    }

                    .b-note {
                        width: 100%;
                    }
    
                }
    
            }

        }

    }

 }
 
 @media (max-width: 575.98px) {
 
     #wrapper {
 
         display: block;
         height: 100vh;
         height: var(--app-height);
 
         #header {
 
             padding: 20px 15px;
 
             .header__search {
                 padding-right: 15px;
 
                 input {
                     height: 45px;
                     padding: 0 20px 0 55px;
                 }
 
                 &::before {
                     left: 16px;
                 }
                 
             }
 
             .header__buttons > * {
                 
                 width: 60px;
                 padding: 0;
 
                 span::before {
                     margin-right: 0;
                 }
 
                 small {
                     display: none;
                 }
 
             }
 
         }
 
         #main {
             height: calc(100% - 75px);
             flex: inherit;
             padding-bottom: 10px;
             position: fixed;
 
             #content {
 
                 height: calc(100% - 94px);
     
                 .b-notes {
                     flex-direction: column;
                     flex-wrap: inherit;

                    .notes__wrp {
                        padding: 0 13px;
                    }
     
                     .b-note, .b-note:nth-child(2) {
                         width: 100%;
             
                         &:first-child {
                             margin-top: 0;
                         }
             
                     }
     
                 }
     
                 .b-content {
                     padding: 0 15px;
     
                     .b-editor {
                         height: 315px;
                         padding-bottom: 10px;
                     }
 
                     .content__title input {
                         height: 45px;
                         padding: 0 95px 0 20px;
                     }
 
                     .content__title .b-title_buttons button {
                         width: 28px;
                         height: 28px;
                         margin-right: 10px;
                     }
 
                     .b-editor .editor .ql-toolbar {
                         padding: 10px 16px;
                     }
     
                 }
     
             }
 
         }
 
         #sidebar {
 
             height: 75px;
             padding: 0 5px;
 
             ul {
                 
                 li button span {
                     height: 45px;
                     background-size: 18px;
                 }
 
             }
 
         }
 
     }
 
     .ql-toolbar.ql-snow .ql-formats:first-child {
         display: none;
     }
 
     .ql-toolbar.ql-snow .ql-formats:nth-child(2) {
         margin: 0;
     }
 
     .ql-editor {
         padding: 20px;
         padding-bottom: 0;
     }
 
     .auth .b-login {
         margin-top: -100px;
     }
 
    }

    .b-note.m-loading {
        height: 78.2px;
        position: relative;
        overflow: hidden;
        isolation: isolate;
    }
 
   .content__title.m-loading::after, .b-editor.m-loading::after, .b-note.m-loading::after {
 
     content: ' ';
     display: block;
     width: 100%;
     height: 100%;
     position: absolute;
     z-index: 2;
     top: 0;
     left: 0;
 
     background-color: #ededed;
     background: linear-gradient(
       100deg,
       rgba(255, 255, 255, 0) 40%,
       rgba(255, 255, 255, .1) 50%,
       rgba(255, 255, 255, 0) 60%
     ) #25262d;
     background-size: 200% 100%;
     background-position-x: 180%;
     animation: 1s loading ease-in-out infinite;
   }
   
   @keyframes loading {
     to {
       background-position-x: -20%;
     }
   }
   
   .loading h4 {
     min-height: 1.6rem;
     border-radius: 4px;
     animation-delay: .05s;
   }
   
   .loading .description {
     min-height: 4rem;
     border-radius: 4px;
     animation-delay: .06s;
   }
 
 
   .m-link.m-submit {
     position: relative;
     overflow: hidden;
     isolation: isolate;
   }
 
   .m-link.m-submit:disabled {
     cursor: none;
     opacity: 0.5;
   }
 
   .m-link.m-submit::before {
     content: "";
     width: 100%;
     height: 100%;
     background: url(./img/loading.svg) #5c4ffe no-repeat center center;
     background-size: 20px;
     position: absolute;
     top: 0;
     left: 0;
     opacity: 0;
 }
 
 .m-link.m-submit.m-loading::before {
     opacity: 1;
 }
 
 .ql-toolbar.ql-snow .ql-formats {
     margin: 0 0 0 15px;
 }
 
 .ql-toolbar.ql-snow .ql-formats:first-child {
     margin: 0;
 }

.tox-tinymce, .tox .tox-edit-area::before {
    border: none !important;
}

.tox .tox-toolbar, .tox .tox-toolbar__overflow, .tox .tox-toolbar__primary, .tox .tox-menubar, .tox:not(.tox-tinymce-inline) .tox-editor-header, .tox .tox-toolbar-overlord {
    background-color: #25262d !important;
}

.tox:not(.tox-tinymce-inline) .tox-editor-header {
    min-height: 48px;
    border-bottom: 1px solid #1b1c21 !important;
}

.tox .tox-mbtn, .tox .tox-edit-area__iframe {
    background: #25262d !important;
    color: #fff !important;
}

.tox .tox-tbtn--disabled, .tox .tox-tbtn--disabled:hover, .tox .tox-tbtn:disabled, .tox .tox-tbtn:disabled:hover, .tox .tox-tbtn--bespoke, .tox .tox-tbtn {
    background: #25262d !important;
}

.tox .tox-tbtn svg, .tox .tox-tbtn--disabled svg, .tox .tox-tbtn--disabled:hover svg, .tox .tox-tbtn:disabled svg, .tox .tox-tbtn:disabled:hover svg, .tox-toolbar__overflow .tox-tbtn:disabled:hover svg, .tox-toolbar__overflow .tox-tbtn:hover svg {
    fill: #fff !important;
}

.tox .tox-tbtn {
    color: #fff !important;
}

.tox .tox-menu.tox-collection.tox-collection--list {
    background-color: #2d2f37 !important;
    border-radius: 8px !important;
}

.tox .tox-collection__item {
    border-radius: 8px !important;
    color: #fff !important;
}

.tox .tox-collection--list .tox-collection__group {
    border-color: #2d2f37 !important;
}

.tox .tox-collection--list .tox-collection__item--active {
    background-color: #5c50fe !important;
}

.tox .tox-mbtn:focus:not(:disabled)::after {
    box-shadow: none !important;
}

.tox .tox-collection--list .tox-collection__item--enabled {
    background-color: #1b1c21 !important;
}

.tox.tox-tinymce-aux .tox-toolbar__overflow {
    background-color: #1b1c21 !important;
    border-radius: 8px !important;
}

.tox .tox-split-button:focus::after, .tox .tox-tbtn--enabled:focus::after, .tox .tox-tbtn:focus::after {
    display: none !important;
}

.tox .tox-split-button:hover {
    box-shadow: none !important;
}

.tox .tox-split-button:focus, .tox-collection__item-label > * {
    background: none !important;
}

.tox .tox-menu {
    background-color: #1b1c21 !important;
}

.tox-toolbar__overflow .tox-toolbar__group {
    gap: 5px !important;
}

.tox .tox-insert-table-picker .tox-insert-table-picker__selected {
    background-color: #5c4ffe !important;
}

.tox .tox-button {
    background-color: #5c4ffe !important;
    border-color: #5c4ffe !important;
}

.tox .tox-insert-table-picker {
    background-color: #2d2f37 !important;
}

.tox .tox-insert-table-picker__label, .tox .tox-button--naked {
    color: #fff !important;
}

.tox-button.tox-button--secondary {
    color: #fff !important;
    background: rgb(255, 63, 63) !important;
    border-color: rgb(255, 63, 63) !important;
}

.tox .tox-dialog {
    background-color: #1b1c21 !important;
    border-color: #1b1c21 !important;
}

.tox .tox-dialog__header {
    background-color: #1b1c21 !important;
    color: #fff !important;
    padding: 25px 25px 0 25px !important;
}

.tox .tox-dialog__footer {
    background-color: #1b1c21 !important;
    padding: 0 25px 25px !important;
}

.tox .tox-label {
    color: #fff !important;
    margin-bottom: 10px !important;
}

.tox .tox-textfield, .tox .tox-textarea {
    color: #fff !important;
    background-color: #25262d !important;
    border-color: #25262d !important;
}

.tox .tox-textfield:focus {
    border-color: #25262d !important;
    box-shadow: none !important;
}

.tox .tox-form__group {
    margin-bottom: 10px !important;
}

.tox .tox-collection--toolbar .tox-collection__item--active {
    background: none !important;
    color: #5c4ffe !important;
}

.tox .tox-textarea-wrap {
    border-color: #25262e !important;
    border-radius: 10px !important;
}

.tox .tox-listboxfield .tox-listbox--select, .tox .tox-textarea, .tox .tox-textarea-wrap .tox-textarea:focus, .tox .tox-textfield, .tox .tox-toolbar-textfield {
    background-color: #25262d !important;
    border-color: #25262d !important;
    color: #fff !important;
    padding: 5px 15px !important;
    min-height: 40px !important;
    border-radius: 10px !important;
}

.tox .tox-custom-editor:focus-within, .tox .tox-listboxfield .tox-listbox--select:focus, .tox .tox-textarea-wrap:focus-within, .tox .tox-textarea:focus, .tox .tox-textfield:focus {
    background-color: #25262e!important;
    box-shadow: none!important;
}

.tox .tox-dialog__body {
    color: #fff!important;
}

.tox .tox-dialog__body-content {
    padding: 25px !important;
}

.tox .tox-button::before {
    display: none !important;
}

.tox .tox-textarea, .tox .tox-textarea-wrap .tox-textarea:focus {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

.tox .tox-dialog__body-nav-item {
    color: #fff !important;
}

.tox .tox-dialog__body-nav-item--active {
    border-bottom: none !important;
    color: #5c50fe !important;
}

.tox-collection__item-label > * {
    line-height: 1 !important;
}

.tox .tox-pop__dialog {
    background-color: #1b1c21 !important;
    border-color: #1b1c21 !important;
}

.tox .tox-pop.tox-pop--top::before, .tox .tox-pop.tox-pop--top::after {
    border-color: transparent transparent #1b1c21 transparent !important;
}

.tox-pop__dialog .tox-toolbar {
    background-color: #1b1c21 !important;
}

.tox-pop__dialog .tox-tbtn {
    background: #1b1c21 !important;
}

.tox .tox-tbtn:hover svg, .tox .tox-tbtn--active svg, .tox .tox-tbtn--enabled svg, .tox .tox-tbtn--enabled:focus svg, .tox .tox-tbtn--enabled:hover svg {
    fill: #5c50fe !important;
}

.tox .tox-tbtn--disabled svg, .tox .tox-tbtn--disabled:hover svg, .tox .tox-tbtn:disabled svg, .tox .tox-tbtn:disabled:hover svg {
    fill: rgb(255 255 255 / 50%) !important;
}

.tox-tooltip {
    display: none !important;
}

.tox-tooltip > * {
    background: #1b1c21 !important;
}

.tox .tox-toolbar__group {
    padding: 0 5px !important;
}

.tox .tox-toolbar-overlord .tox-toolbar__primary {
    padding: 0 !important;
}

.tox .tox-dialog-wrap {
    padding: 15px 0 !important;
}

.tox .tox-dialog__body-nav-item {
    border-bottom: none !important;
}

.tox .tox-dialog__body-nav-item:focus {
    background-color: transparent !important;
}

.tox:not([dir=rtl]) .tox-color-input .tox-textfield {
    padding-left: 36px !important;
}

.tox .tox-dialog .tox-tiered-menu .tox-menu.tox-collection.tox-collection--list {
    background-color: #25262e !important;
}

.tox .tox-color-input span {
    top: 8px !important;
}

.tox input.tox-checkbox__input:focus+.tox-checkbox__icons {
    box-shadow: none !important;
}

.tox .tox-checkbox__icons .tox-checkbox-icon__unchecked svg {
    fill: #25262e !important;
}

.tox .tox-checkbox__icons .tox-checkbox-icon__checked svg {
    fill: #5c50fe !important;
}

.tox .tox-toolbar__overflow {
    background-image: none !important;
    border-top: 1px solid #1b1c21;
}

.tox .tox-dialog-wrap__backdrop {
    background-color: rgb(37 38 45 / 85%) !important;
}

.tox .tox-tbtn--bespoke .tox-tbtn__select-label {
    width: auto !important;
    min-width: 35px !important;
}

.tox .tox-tbtn--select {
    width: auto !important;
}

.Toastify__close-button {
    display: none;
    align-self: center;
}

.Toastify__toast {
    min-height: 40px;
    border-radius: 8px;
    padding: 0 25px;
}

.Toastify__toast-theme--dark {
    background: #5c50fe;
}

.Toastify__toast--error {
    background: #ff403f;
}

.Toastify__progress-bar-theme--dark {
    background: #ffffff;
}

.Toastify__toast-container--bottom-right {
    right: 30px;
    bottom: 15px
}

.Toastify__toast-body {
    font-size: 14px;
    padding: 0;
    text-align: center;
}

.Toastify__toast-container {
    width: auto;
    min-width: 200px;
    max-width: 320px;
}

.Toastify__progress-bar--wrp {
    height: 3px;
}

.Toastify__toast-icon svg {
    fill: #fff;
    opacity: 0.7;
}

@media only screen and (max-width: 480px) {

    .Toastify__toast-container--bottom-right {
        right: 20px;
        bottom: inherit;
        left: inherit;
        top: 20px;
    }

    .Toastify__toast {
        display: none;
        margin-bottom: 10px;
    }

}